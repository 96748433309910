import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_EQUIPMENTTYPE,
  DELETE_EQUIPMENTTYPE,
  EQUIPMENTTYPE_FORM_TOOGLE_LOADING,
  SET_EQUIPMENTTYPE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_EQUIPMENTTYPE,
} from "../constants";
import { formatEquipmentTypes } from "./settingsActionsUtils";

/* EQUIPMENTTYPE LIST */
export const fetchEquipmentTypes = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.equipmentTypes.list)
      .then((response) => {
        const equipmentTypes = formatEquipmentTypes(response.data);
        dispatch({
          type: SET_EQUIPMENTTYPE_LIST,
          payload: keyBy(equipmentTypes, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// EQUIPMENTTYPES
export const createEquipmentType = (newEquipmentType) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.equipmentTypes.create, newEquipmentType)
      .then((response) => {
        const equipmentType = formatEquipmentTypes(response.data);
        dispatch({ type: ADD_NEW_EQUIPMENTTYPE, payload: equipmentType });
        dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Motivo creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateEquipmentType = (updatedEquipmentType) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.equipmentTypes.edit}${updatedEquipmentType?._id}`,
        updatedEquipmentType
      )
      .then((response) => {
        const equipmentType = formatEquipmentTypes(response.data);
        dispatch({ type: UPDATE_EQUIPMENTTYPE, payload: equipmentType });
        dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Motivo actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteEquipmentTypes = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.equipmentTypes.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_EQUIPMENTTYPE, payload: Ids });
        dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Motivo eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: EQUIPMENTTYPE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
