import configuration from "../assets/menuImages/Configuración.png";
import instalation from "../assets/menuImages/Instalaciones.png";
import incidents from "../assets/menuImages/Incidencias.png";
import satisfactions from "../assets/menuImages/Satisfacciones.png";
import users from "../assets/menuImages/Usuario.png";
import equipment from "../assets/menuImages/Equipos.png";
import singUp from "../assets/menuImages/datafono.png";
import business from "../assets/menuImages/business-man.png";
import deliveryNote from "../assets/menuImages/albaran.png";
import calendario from "../assets/menuImages/calendario.png";
import beneficios from "../assets/menuImages/beneficios.png";
import buzon from "../assets/menuImages/buzon.png";
import gastos from "../assets/menuImages/gastos.png";
import tags from "../assets/menuImages/tags.png";
import reasons from "../assets/menuImages/lightbulb.png";
import permiso from "../assets/menuImages/permiso.png";
import vacaciones from "../assets/menuImages/vacaciones-de-verano.png";
import equipo from "../assets/menuImages/equipo.png";
import cambio from "../assets/menuImages/cambio.png";
import clock from "../assets/menuImages/clock.png";
import component from "../assets/menuImages/pc-tower.png";
import manteniment from "../assets/menuImages/mantenimiento.png";
import map from "../assets/menuImages/mapa.png";
import firmar from "../assets/menuImages/firmar.png";
import equipmentTypes from "../assets/menuImages/maintenance.png";

export const PRINCIPALMENU = [
  {
    keyApp: "configuration",
    navigation: "configuration",
    title: "Configuración",
    subtitle: "Usuarios, roles",
    image: `url(${configuration})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "instalationsMenu",
    navigation: "instalations",
    title: "Instalación",
    subtitle: "Instalaciones y equipos",
    image: `url(${instalation})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "incidencesMenu",
    navigation: "incidences",
    title: "Incidencias",
    subtitle: "Tus incidencias y partes de trabajo",
    image: `url(${incidents})`,
    hasChildren: true,
    showInMenu: true,
  },
  // {
  //   keyApp: "shopCarts",
  //   navigation: "shopCarts",
  //   title: "Compras",
  //   subtitle: "Carritos y Comprar",
  //   image: `url(${buys})`,
  // },
  {
    keyApp: "satisfactions",
    navigation: "satisfactions",
    title: "Satisfacciones",
    subtitle: "Satisfacciones sobre las incidencias",
    image: `url(${satisfactions})`,
    showInMenu: true,
  },
  {
    keyApp: "stadistics",
    navigation: "stadistics",
    title: "Estadísticas",
    subtitle: "Estadísticas sobre la aplicación",
    image: `url(${beneficios})`,
    showInMenu: true,
  },
  // {
  //   keyApp: "projects",
  //   navigation: "projects",
  //   title: "Proyectos",
  //   subtitle: "Proyectos y diagrama de gantt",
  //   image: `url(${projects})`,
  // },
  {
    keyApp: "singUp",
    navigation: "singUp",
    title: "Fichar",
    subtitle: "Fichar entradas y salidas",
    image: `url(${singUp})`,
    showInMenu: true,
  },
  {
    keyApp: "singUpsMenu",
    navigation: "singUps",
    title: "Control de accesos",
    subtitle: "Ver todos los accesos",
    image: `url(${business})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "RRHH",
    navigation: "RRHH",
    title: "RRHH",
    subtitle: "Recursos humanos",
    image: `url(${equipo})`,
    hasChildren: true,
    showInMenu: true,
  },
  // {
  //   keyApp: "dosification",
  //   navigation: "dosification",
  //   title: "Dosificación",
  //   subtitle: "Productos y configuración",
  //   image: `url(${productos})`,
  // },
  // {
  //   keyApp: "invoicesDeliveryNotes",
  //   navigation: "allInvoicesDeliveryNotes",
  //   title: "Facturas y albaranes",
  //   subtitle: "Descargar las facturas y albaranes de este año",
  //   image: `url(${invoice})`,
  // },
  // {
  //   keyApp: "repartiments",
  //   navigation: "repartiments",
  //   title: "Repartos",
  //   subtitle: "Ver todos los albaranes de hoy",
  //   image: `url(${camionReparto})`,
  // },
  {
    keyApp: "suggestions",
    navigation: "suggestion",
    title: "Sugerencias",
    subtitle: "Introduce errores o sugerencias",
    image: `url(${buzon})`,
    showInMenu: true,
  },
  {
    keyApp: "expenses",
    navigation: "expenses",
    title: "Gastos",
    subtitle: "Introduce gastos",
    image: `url(${gastos})`,
    showInMenu: true,
  },
  {
    keyApp: "salesShipments",
    navigation: "salesShipments",
    title: "Repartos",
    subtitle: "Repartos",
    image: `url(${gastos})`,
    showInMenu: true,
  },
  {
    keyApp: "rapports",
    navigation: "rapports",
    title: "Rapports repartos",
    subtitle: "Rapports repartos",
    image: `url(${gastos})`,
    showInMenu: true,
  },
  // {
  //   keyApp: "opcua",
  //   navigation: "opcua",
  //   title: "OPCUA",
  //   subtitle: "Introduce la configuración del OPCUA",
  //   image: `url(${conexion})`,
  // },
];

export const CONFIGURATIONMENU = [
  {
    keyApp: "users",
    navigation: "configuration/users",
    title: "Usuarios",
    subtitle: "Todos los usuarios",
    image: `url(${users})`,
    showInMenu: true,
  },
  // {
  //   keyApp: "configurations",
  //   navigation: "configuration/configurations",
  //   title: "Configuración de equipos",
  //   subtitle: "Campos visibles según el usuario",
  //   image: `url(${equipmentConfiguration})`,
  // },
  // {
  //   keyApp: "downloadDocument",
  //   navigation: "configuration/downloadDocument",
  //   title: "Descargas de documentos",
  //   subtitle: "Ver todas las descargas de documentos",
  //   image: `url(${download})`,
  // },
  {
    keyApp: "tags",
    navigation: "configuration/tags",
    title: "Tags",
    subtitle: "Ver todas las tags de gastos",
    image: `url(${tags})`,
    showInMenu: true,
  },
  {
    keyApp: "reasons",
    navigation: "configuration/reasons",
    title: "Motivos",
    subtitle: "Ver todos los motivos de horas extras",
    image: `url(${reasons})`,
    showInMenu: true,
  },
  {
    keyApp: "roles",
    navigation: "configuration/roles",
    title: "Roles",
    subtitle: "Ver todos los roles",
    image: `url(${permiso})`,
    showInMenu: true,
  },
];

// export const PROJECTSMENU = [
//   {
//     keyApp: "projectsList",
//     navigation: "projects/projects",
//     title: "Proyectos",
//     subtitle: "Todos los proyectos",
//     image: `url(${projects})`,
//   },
//   {
//     keyApp: "ganttDiagram",
//     navigation: "projects/ganttDiagram",
//     title: "Diagrama de gantt",
//     subtitle: "Vista del diagrama de gantt",
//     image: `url(${ganttDiagram})`,
//   },
// ];

// export const SHOPMENU = [
//   {
//     keyApp: "carts",
//     navigation: "shopCarts/carts",
//     title: "Carritos",
//     subtitle: "Todos los carritos",
//     image: `url(${shopCar})`,
//   },
//   {
//     keyApp: "buy",
//     navigation: "shopCarts/buy",
//     title: "Comprar",
//     subtitle: "Realiza compras",
//     image: `url(${buys})`,
//   },
//   {
//     keyApp: "security",
//     navigation: "shopCarts/security",
//     title: "Fichas técnicas",
//     subtitle: "Ver todas las fichas técnicas descargadas",
//     image: `url(${security})`,
//   },
// ];

export const INSTALATIONMENU = [
  {
    keyApp: "instalations",
    navigation: "instalations/instalation",
    title: "Instalaciones",
    subtitle: "Tus instalaciones",
    image: `url(${instalation})`,
    showInMenu: true,
  },
  {
    keyApp: "equipments",
    navigation: "instalations/equipment",
    title: "Equipos",
    subtitle: "Tus equipos",
    image: `url(${equipment})`,
    showInMenu: true,
  },
  {
    keyApp: "equipmentTypes",
    navigation: "instalations/equipmentTypes",
    title: "Tipo de equipos",
    subtitle: "Ver todos los tipos de equipos",
    image: `url(${equipmentTypes})`,
    showInMenu: true,
  },
  {
    keyApp: "components",
    navigation: "instalations/components",
    title: "Componentes",
    subtitle: "Ver todos los componentes",
    image: `url(${component})`,
    showInMenu: true,
  },
  {
    keyApp: "componentTypes",
    navigation: "instalations/componentTypes",
    title: "Tipos de componentes",
    subtitle: "Ver todos los tipos de componentes",
    image: `url(${component})`,
    showInMenu: true,
  },
  {
    keyApp: "maintenances",
    navigation: "instalations/maintenances",
    title: "Mantenimiento",
    subtitle: "Mantenimientos de equipos e instalaciones",
    image: `url(${manteniment})`,
    showInMenu: true,
  },
];

export const INCIDENCESMENU = [
  {
    keyApp: "incidences",
    navigation: "incidences/incidences",
    title: "Incidencias",
    subtitle: "Tus incidencias",
    image: `url(${incidents})`,
    showInMenu: true,
  },
  {
    keyApp: "workOrders",
    navigation: "incidences/workOrders",
    title: "Partes de trabajo",
    subtitle: "Tus partes de trabajo",
    image: `url(${firmar})`,
    showInMenu: true,
  },
];

// export const INVOICESMENU = [
//   {
//     keyApp: "invoices",
//     navigation: "allInvoicesDeliveryNotes/invoices",
//     title: "Facturas",
//     subtitle: "Tus facturas",
//     image: `url(${invoice})`,
//   },
//   {
//     keyApp: "deliveryNotes",
//     navigation: "allInvoicesDeliveryNotes/deliveryNotes",
//     title: "Albaranes",
//     subtitle: "Tus albaranes",
//     image: `url(${deliveryNote})`,
//   },
// ];

export const SINGUPS = [
  {
    keyApp: "singUps",
    navigation: "singUps/singUpsList",
    title: "Control de accesos",
    subtitle: "Ver todos los accesos",
    image: `url(${business})`,
    showInMenu: true,
  },
  {
    keyApp: "timeRequests",
    navigation: "singUps/timeRequest",
    title: "Peticiones",
    subtitle: "Peticiones de cambio de fecha",
    image: `url(${deliveryNote})`,
    showInMenu: true,
  },
  {
    keyApp: "map",
    navigation: "singUps/map",
    title: "Mapa",
    subtitle: "Mapa con los fichajes",
    image: `url(${map})`,
    showInMenu: true,
  },
];

export const RRHH = [
  {
    keyApp: "mySingUp",
    navigation: "RRHH/mySingUp",
    title: "Mis accesos",
    subtitle: "Consultar mis salidas y entradas",
    image: `url(${calendario})`,
    showInMenu: true,
  },
  {
    keyApp: "holidayRequests",
    navigation: "RRHH/holidayRequest",
    title: "Vacaciones",
    subtitle: "Peticiones de vacaciones y bajas",
    image: `url(${vacaciones})`,
    showInMenu: true,
  },
  {
    keyApp: "workShifts",
    navigation: "RRHH/workShift",
    title: "Turnos",
    subtitle: "Turnos de trabajo",
    image: `url(${cambio})`,
    showInMenu: true,
  },
  {
    keyApp: "leaves",
    navigation: "RRHH/leave",
    title: "Bajas",
    subtitle: "Bajas de trabajo",
    image: `url(${clock})`,
    showInMenu: true,
  },
];

// export const DOSIFICATION = [
//   {
//     keyApp: "dosification",
//     navigation: "dosification/dosification",
//     title: "Dosificación",
//     subtitle: "Productos y configuración",
//     image: `url(${productos})`,
//     showInMenu: true,
//   },
//   {
//     keyApp: "dosificationProducts",
//     navigation: "dosification/dosificationProducts",
//     title: "Productos dosificados",
//     subtitle: "Dosificaciones",
//     image: `url(${produccion})`,
//     showInMenu: true,
//   },
// ];

// export const EPIS = [
//   {
//     keyApp: "epis",
//     navigation: "epis/epis",
//     title: "Control de EPIS",
//     subtitle: "Control de EPIS",
//     image: `url(${casco})`,
//     showInMenu: true,
//   },
//   {
//     keyApp: "raw",
//     navigation: "epis/raw",
//     title: "Materias primas",
//     subtitle: "Materias primas",
//     image: `url(${construccion})`,
//     showInMenu: true,
//   },
// ];

export const BASICMENU = {
  configuration: CONFIGURATIONMENU,
  //shopCarts: SHOPMENU,
  instalationsMenu: INSTALATIONMENU,
  //projects: PROJECTSMENU,
  singUpsMenu: SINGUPS,
  RRHH,
  //invoicesDeliveryNotes: INVOICESMENU,
  incidencesMenu: INCIDENCESMENU
}

export const ALLMENU = Array.prototype.concat(
  PRINCIPALMENU,
  CONFIGURATIONMENU,
  // SHOPMENU,
  INSTALATIONMENU,
  // PROJECTSMENU,
  SINGUPS,
  RRHH,
  INCIDENCESMENU
  //INVOICESMENU
);
