import moment from "moment";
import { createCrudActions } from '../utils/crudGenerator';

const formatRapports = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
        hour: item.hour && moment(item.hour),
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchRapports,
    create: createRapport,
    update: updateRapport,
    delete: deleteRapports
} = createCrudActions('RAPPORT', "Rapport repartos", formatRapports);
