import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_MAINTENANCE,
  DELETE_MAINTENANCE,
  EDIT_SELECTED_MAINTENANCE,
  MAINTENANCE_FORM_TOOGLE_LOADING,
  SET_MAINTENANCE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_MAINTENANCE,
} from "../constants";
import { formatMaintenances } from "./settingsActionsUtils";

/* MAINTENANCE LIST */
export const fetchMaintenances = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.maintenances.list)
      .then((response) => {
        const maintenances = formatMaintenances(response.data);
        dispatch({
          type: SET_MAINTENANCE_LIST,
          payload: keyBy(maintenances, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// MAINTENANCES
export const createMaintenance = (newMaintenance) => {
  return async (dispatch) => {
    dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.maintenances.create, newMaintenance)
      .then((response) => {
        const maintenance = formatMaintenances(response.data);
        dispatch({ type: ADD_NEW_MAINTENANCE, payload: maintenance });
        dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Mantenimiento creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateMaintenance = (updatedMaintenance) => {
  return async (dispatch) => {
    dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.maintenances.edit}${updatedMaintenance?._id}`,
        updatedMaintenance
      )
      .then((response) => {
        const maintenance = formatMaintenances(response.data);
        dispatch({ type: UPDATE_MAINTENANCE, payload: maintenance });
        dispatch({ type: EDIT_SELECTED_MAINTENANCE, payload: maintenance })
        dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Mantenimiento actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteMaintenances = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.maintenances.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_MAINTENANCE, payload: Ids });
        dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Mantenimiento eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: MAINTENANCE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
