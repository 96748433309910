import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_COMPONENT,
  DELETE_COMPONENT,
  COMPONENT_FORM_TOOGLE_LOADING,
  SET_COMPONENT_LIST,
  SHOW_NOTIFICATION,
  UPDATE_COMPONENT,
} from "../constants";
import { formatComponents } from "./settingsActionsUtils";

/* COMPONENT LIST */
export const fetchComponents = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.components.list)
      .then((response) => {
        const components = formatComponents(response.data);
        dispatch({
          type: SET_COMPONENT_LIST,
          payload: keyBy(components, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// COMPONENTS
export const createComponent = (newComponent) => {
  return async (dispatch) => {
    dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.components.create, newComponent)
      .then((response) => {
        const component = formatComponents(response.data);
        dispatch({ type: ADD_NEW_COMPONENT, payload: component });
        dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Componente creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateComponent = (updatedComponent) => {
  return async (dispatch) => {
    dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.components.edit}${updatedComponent?._id}`, updatedComponent)
      .then((response) => {
        const component = formatComponents(response.data);
        dispatch({ type: UPDATE_COMPONENT, payload: component });
        dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Componente actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteComponents = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.components.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_COMPONENT, payload: Ids });
        dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Componente eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: COMPONENT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
