import moment from "moment";
import { createCrudActions } from '../utils/crudGenerator';

const formatSalesShipments = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item.No,
        posting_date: item.posting_date && moment(item.posting_date),
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchSalesShipments,
    create: createSalesShipment,
    update: updateSalesShipment,
    delete: deleteSalesShipments
} = createCrudActions('SALESSHIPMENT', "Reparto", formatSalesShipments);
